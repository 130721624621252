<template>
	<ZyroModal
		max-width="375px"
		max-height="480px"
		height="auto"
		:title="activeModalSettings.title"
		:show-close-button="activeModalSettings.isClosable"
		class="notification-modal"
		@close-modal="handleClose"
	>
		<div
			class="notification-modal__message"
			v-text="activeModalSettings.message"
		/>
		<div class="notification-modal__actions">
			<ZyroButton
				v-if="!!activeModalSettings.closeCallback"
				theme="primary"
				color="white"
				@click="handleClose"
				v-text="$t('common.close')"
			/>
			<ZyroButton
				v-if="!!activeModalSettings.submitCallback"
				theme="primary"
				@click="handleSubmit"
				v-text="activeModalSettings.submitLabel"
			/>
		</div>
	</ZyroModal>
</template>

<script>
import { mapState } from 'vuex';

import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';

export default {
	computed: { ...mapState('gui', ['activeModalSettings']) },
	methods: {
		...mapActionsGui({ closeModal: CLOSE_MODAL }),
		handleSubmit() {
			if (!this.activeModalSettings.submitCallback) {
				return;
			}

			this.activeModalSettings.submitCallback();

			if (this.activeModalSettings.closeOnSubmit) {
				this.closeModal();
			}
		},
		handleClose() {
			if (this.activeModalSettings.closeCallback) {
				this.activeModalSettings.closeCallback();
			}

			this.closeModal();
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .modal .modal__content {
	overflow: visible;
}

.notification-modal {
	&__actions {
		display: flex;
		justify-content: center;
		padding: 16px 0;
	}
}
</style>
